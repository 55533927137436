import React from 'react';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import { DefaultProps, PropTypes } from '../../../core/Utils/SitecoreFields';

const MediaContainer = ({ fields }) => {
  return (
    <div className="cm-media-container cm-rich-text">
      <figure>
        {fields.video.value.href && (
          <div className="video-embed" data-amid={fields.video.id}>
            <iframe
              width="560"
              height="315"
              src={`${fields.video.value.href}?&rel=0`}
              frameBorder="0"
              title={fields.videoTitle.value}
              allow="encrypted-media"
              allowFullScreen
            />
          </div>
        )}
        {fields.image.value.src && (
          <div className="image-embed">
            <Image data-amid={fields.image.id} field={fields.image} />
          </div>
        )}
        {fields.quote.value && (
          <figcaption>
            <Text field={fields.quote} data-amid={fields.quote.id} tag="p" />
            {fields.quoteSource.value && (
              <Text
                field={fields.quoteSource}
                data-amid={fields.quoteSource.id}
                tag="p"
              />
            )}
          </figcaption>
        )}
      </figure>
    </div>
  );
};

MediaContainer.propTypes = PropTypes.SitecoreItem.inject({
  video: PropTypes.Link,
  videoTitle: DefaultProps.SingleLineText,
  image: PropTypes.Image,
  quote: PropTypes.SingleLineText,
  quoteSource: PropTypes.SingleLineText,
});

MediaContainer.defaultProps = DefaultProps.SitecoreItem.inject({
  video: DefaultProps.Link,
  videoTitle: DefaultProps.SingleLineText,
  image: DefaultProps.Image,
  quote: DefaultProps.SingleLineText,
  quoteSource: DefaultProps.SingleLineText,
});
export default MediaContainer;
